<template>
  <div class="carousel">
    <hooper
      v-if="slides.length"
      infinite-scroll
      :items-to-show="1"
      :transition="300"
      :center-mode="true"
      :play-speed="6000"
      auto-play
    >
      <slide v-for="(slide, index) in slides" :key="index">
        <component
          :is="slide.type | getSlideType"
          :id="slide.id || 0"
          :slide="slide.data"
        />
      </slide>

      <hooper-navigation slot="hooper-addons" />
      <hooper-pagination slot="hooper-addons" />
    </hooper>
  </div>
</template>

<script>
import SlideTournament from '@components/MainCarousel/SlideTournament';
import SlideNews from '@components/MainCarousel/SlideNews';
import SlideVideo from '@components/MainCarousel/SlideVideo';
import SlideBanner from '@components/MainCarousel/SlideBanner';
import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation,
} from 'hooper';

export default {
  components: {
    Hooper,
    HooperPagination,
    HooperNavigation,
    Slide,
    SlideNews,
    SlideVideo,
    SlideTournament,
    SlideBanner,
  },
  filters: {
    getSlideType(value) {
      const type = {
        tournament: 'slide-tournament',
        news: 'slide-news',
        video: 'slide-video',
        banner: 'slide-banner',
      };
      return type[value];
    },
  },
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.carousel {
  padding: 20px 0 20px;
  overflow: hidden;

  ::v-deep {
    .hooper {
      width: 100%;
      margin: auto;
      height: auto;
      @include min-laptop() {
        max-width: 760px;
      }

      .hooper-list {
        height: auto;
        overflow: visible;
      }
      .hooper-track {
        align-items: center;
      }
    }

    .hooper-pagination {
      position: relative;
      justify-content: center;

      .hooper-indicator {
        width: 30px;
        height: 4px;
        background: #394865;
        margin: 0 4px;
      }

      .hooper-indicator.is-active,
      .hooper-indicator:hover {
        background: #535353;
        box-shadow: 0px 0px 0px 1px #a3a5a7;
      }
    }

    .hooper-navigation {
      display: none;
      @include min-laptop() {
        display: block;
      }

      .hooper-prev,
      .hooper-next {
        width: 38px;
        height: 38px;
        padding: 0;
        color: #ffffff;
        background: #000000;
        border-radius: 6px;

        &:not(:hover) {
          opacity: 0.7;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }
      .hooper-prev {
        left: -64px;
      }
      .hooper-next {
        right: -64px;
      }
    }

    .hooper-slide {
      border: 1px solid #252a3c;
      border-radius: 10px;
      overflow: hidden;
      transform: scale(0.95);
    }
    .hooper-slide.is-prev,
    .hooper-slide.is-next {
      @include min-laptop() {
        transform: scale(0.95);
      }
    }
    .hooper-slide.is-active {
      @include min-laptop() {
        transform: scale(1);
      }
    }
  }
}
</style>
