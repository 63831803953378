<template>
  <main-layout
    :is-loading="loadingState.page"
    :class="{ padded: !slider.length }"
    :footer-spoiler="true"
  >
    <MainCarousel :slides="slider" />

    <div class="pvp-container">
      <template v-if="tournamentsIds.length">
        <headline>
          <h2 slot="title">{{ $t('tournaments.recommended') }}</h2>
          <div slot="addon">
            <pvp-btn
              :to="{ name: 'tournaments' }"
              icon-right="angle-right"
              variant="link"
              @click.native="tournamentClickHandler()"
            >
              {{ $t('tournaments.all') }}
            </pvp-btn>
          </div>
        </headline>

        <div class="tournaments-list">
          <tournament-card
            v-for="id in tournamentsIds"
            :id="id"
            :key="id"
          />
        </div>
      </template>

      <section class="games-filter-section">
        <GamesFilter
          :as-links="true"
          @input="goToTournamentsPage"
          @filter-change="gamesFilterChangeHandler"
        />
      </section>

      <section class="ratings-section">
        <headline>
          <h2 slot="title">{{ $t('ratings.few') }}</h2>
          <div slot="addon">
            <pvp-btn
              :to="{ name: 'ratings' }"
              icon-right="angle-right"
              variant="link"
              @click-native="moreRatingsClickHandler"
            >
              {{ $t('ratings.all') }}
            </pvp-btn>
          </div>
        </headline>

        <div class="ratings-table-list">
          <div
            v-for="rating in ratings"
            :key="rating.id"
            class="rating-table-container"
          >
            <h4 class="rating-table-title">
              <BaseLink
                :to="{ name: 'rating', params: { id: rating.id } }"
                @click-native="ratingClickHandler(rating.name)"
                >{{ rating.name }}</BaseLink
              >
            </h4>
            <rating-table :rating="rating" />
            <pvp-btn
              :to="{ name: 'rating', params: { id: rating.id } }"
              :block="true"
              class="about-rating-button"
              variant="secondary"
              @click-native="ratingClickHandler(rating.name)"
            >
              {{ $t('ratings.more') }}
            </pvp-btn>
          </div>
        </div>
      </section>

      <headline>
        <h2 slot="title">{{ $t('news.few') }}</h2>
        <div slot="addon">
          <pvp-btn
            :to="{ name: 'news' }"
            icon-right="angle-right"
            variant="link"
          >
            {{ $t('news.all') }}
          </pvp-btn>
        </div>
      </headline>

      <div class="news-list">
        <MainCard
          v-for="card in news"
          :key="card.id"
          :data="card"
          :link-to="`/news/${card.id}`"
        />
      </div>

      <headline>
        <h2 slot="title">{{ $t('video.few') }}</h2>
        <div slot="addon">
          <pvp-btn
            :to="{ name: 'videos' }"
            icon-right="angle-right"
            variant="link"
          >
            {{ $t('video.all') }}
          </pvp-btn>
        </div>
      </headline>

      <div class="videos-list">
        <MainCard
          v-for="video in videos"
          :key="video.id"
          :data="video"
          :link-to="`/videos/${video.id}`"
        />
      </div>
    </div>

    <template slot="seo-text">
      <p v-for="(seoTextItem, key) in seoText" :key="key">
        {{ seoTextItem }}
      </p>
    </template>
  </main-layout>
</template>

<script>
import { pushEvents } from '@utils/metricEvents.js';
import MainCard from '@components/v2/cards/MainCard.vue';
import GamesFilter from '@components/GameComponents/GamesFilter.vue';
import RatingTable from '@components/RatingComponents/RatingTable.vue';
import TournamentCard from '@components/TournamentComponents/TournamentCard.vue';
import MainCarousel from '@components/MainCarousel/Carousel.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'MainPage',
  components: {
    TournamentCard,
    RatingTable,
    GamesFilter,
    MainCard,
    MainCarousel,
    BaseLink,
  },

  page() {
    return {
      title: this.$t('global.mainPage'),
    };
  },

  data() {
    return {
      news: [],
      videos: [],
      slider: [],
      tournamentsIds: [],
      ratings: [],
    };
  },

  computed: {
    ...mapGetters('application', ['getGame']),

    seoText() {
      return [this.$t('seo.home_0'), this.$t('seo.home_1')];
    },
  },

  created() {
    this.loadData();
  },

  methods: {
    ...mapActions('application', ['fetchMainPageData']),

    loadData() {
      this.loadingState.page = true;

      this.fetchMainPageData()
        .then((data) => {
          this.slider = data.slider;
          this.tournamentsIds = data.tournamentsIds;
          this.news = data.news.slice(0, 3);
          this.videos = data.video;
          this.ratings = data.ratings.slice(0, 3);
        })
        .finally(() => {
          this.loadingState.page = false;
        });
    },

    moreRatingsClickHandler() {
      // ======================================================
      // METRICS Турниры. Клик по элементу блока популярные игры на главной
      const tmrParams = {
        category: 'pvp',
        action: 'ratings_click',
        label: 'все рейтинги',
        url: window.location.href,
      };
      pushEvents('pvp_ratings_click', tmrParams);
      // ======================================================
    },

    ratingClickHandler(ratingName) {
      // ======================================================
      // METRICS Турниры. Клик по элементу блока популярные игры на главной
      const tmrParams = {
        category: 'pvp',
        action: 'ratings_click',
        label: ratingName,
        url: window.location.href,
      };
      pushEvents('pvp_ratings_click', tmrParams);
      // ======================================================
    },

    tournamentClickHandler() {
      // ======================================================
      // METRICS Турниры. Клик по элементу блока рекомендуемые
      const tmrParams = {
        category: 'pvp',
        action: 'tourn_recom_click',
        label: 'кнопка все турниры',
        url: window.location.href,
      };
      pushEvents('pvp_tourn_recom_click', tmrParams);
      // ======================================================
    },

    gamesFilterChangeHandler(gameName) {
      // ======================================================
      // METRICS Турниры. Клик по элементу блока популярные игры на главной
      const tmrParams = {
        category: 'pvp',
        action: 'main_popgames_click',
        label: gameName,
      };
      pushEvents('pvp_main_popgames_click', tmrParams);
      // ======================================================
    },

    goToTournamentsPage([gameId]) {
      if (!gameId) {
        return;
      }
      const game = this.getGame(gameId);
      this.$router.push({
        name: 'tournaments',
        query: {
          games: game.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .headline {
  margin-top: 50px;

  @include max-laptop() {
    margin-top: 24px;
  }

  & + div {
    margin-top: 20px;
  }
}

.tournaments-list {
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-columns: 1fr;

  @include min-tablet() {
    grid-template-columns: repeat(2, 1fr);
  }
}

.ratings-section {
  .ratings-table-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 32px;

    @include min-tablet() {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 24;
    }

    .rating-table-container {
      .rating-table-title {
        font-size: 19px;
        height: 44px;
        display: flex;
        align-items: center;

        a {
          color: white;
        }
      }

      .rating-table {
        margin-top: 12px;
      }

      .about-rating-button {
        margin-top: 12px;
      }
    }
  }
}

.post-card + .post-card {
  margin-top: 24px;
}

.videos-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 24px;

  @include max-laptop() {
    grid-template-columns: repeat(2, 1fr);
  }

  @include max-tablet() {
    grid-template-columns: 1fr;
  }
}

.news-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 24px;

  @include min-tablet() {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
